import { Row } from 'reactstrap'
import { useState } from 'react'
import MarkerCell from './MarkerCell'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../store/hooks'
import { listingsSearch } from '../../../store/search/actions'
import { updateQuery } from '../../common-ui/helpers'
import MarkerIcon from '../../common-ui/MarkerIcon'

const markerTypes1 = [
  { description: 'For Sale', color: '#22863b' },
  { description: 'For Rent', color: '#e2610a' },
]
const markerTypes2 = [
  { description: 'Dealer', color: '#b20404' },
  { description: 'Community', color: '#91278f' },
]

const Legend = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(window.innerWidth < 768 ? false : true)
  if (open)
    return (
      <div className="legend__wrapper" onClick={() => setOpen(!open)}>
        <Row className="legend__row">
          {markerTypes1.map((e: { color: string; description: string }) => (
            <MarkerCell
              updateQuery={(updates) =>
                updateQuery({ updates, navigate, dispatch, listingsSearch })
              }
              key={e.color}
              color={e.color}
              description={e.description}
            />
          ))}
        </Row>
        <Row className="legend__last__row">
          {markerTypes2.map((e: { color: string; description: string }) => (
            <MarkerCell
              updateQuery={(updates) =>
                updateQuery({ updates, navigate, dispatch, listingsSearch })
              }
              key={e.color}
              color={e.color}
              description={e.description}
            />
          ))}
        </Row>
      </div>
    )
  else
    return (
      <div className="legend__wrapper_closed" onClick={() => setOpen(!open)}>
        <MarkerIcon color={'#000000'} />
      </div>
    )
}

export default Legend
