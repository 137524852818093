import { ICommunity, IMHBOListing, IMobileHome } from 'mhbo-js'
import { FunctionComponent, useState, useEffect } from 'react'
import { Element } from 'react-scroll'
import PaginationComponent from '../../PaginationComponent/PaginationComponent'
import AdComponent from '../AdComponent'
import ListingPreviewCell from '../ListingPreviewCell/index'
import { Launch } from '@mui/icons-material'

interface IProps {
  currentSelection: Array<IMobileHome | ICommunity>
  selectedLocation: number
  isFirstLoad: boolean
  updateFirstLoad: () => void
  updateSelectedLocation: (selectedLocation: number) => void
  recents: Array<IMobileHome | ICommunity>
  showRecentsPanel: boolean
  openSelectedUrl: (id: number) => void
}

const ListingsView: FunctionComponent<IProps> = ({
  currentSelection,
  selectedLocation,
  isFirstLoad,
  updateFirstLoad,
  updateSelectedLocation,
  showRecentsPanel,
  recents,
  openSelectedUrl,
}: IProps) => {
  const [ads, setAds] = useState<number[]>([])

  useEffect(() => {
    let adIndex = 2
    const newads: number[] = []
    while (adIndex < currentSelection.length) {
      newads.push(adIndex)
      adIndex += Math.random() < 0.5 ? 3 : 4
    }
    if (newads.length !== ads.length) {
      setAds(newads)
    }
  }, [currentSelection])
  if (isFirstLoad && selectedLocation !== 1) {
    const selectedListing = currentSelection[0] as any
    if (selectedListing && selectedListing.id === selectedLocation) {
      return (
        <>
          <Element name={`listing${selectedListing.id}`}>
            <ListingPreviewCell
              listing={selectedListing}
              isLazy={false}
              onClick={() => openSelectedUrl(selectedListing.id)}
            />
            {/* <AdComponent /> */}

            <div
              dangerouslySetInnerHTML={{
                __html:
                  selectedListing.description ||
                  selectedListing.propertyDescription,
              }}
            />
            <p
              className="listingCell__community_link"
              onClick={() => openSelectedUrl(selectedListing.id)}
            >
              See Details
              <Launch />
            </p>
          </Element>
        </>
      )
    }
  }
  const showSelection = showRecentsPanel ? recents : currentSelection
  return (
    <div>
      {showSelection.map((listing: IMHBOListing, index: number) => {
        return (
          <span key={listing.id}>
            {/* ads.includes(index) ? <AdComponent key={`ad${index}`} /> : null */}
            <Element name={`listing${listing.id}`}>
              <ListingPreviewCell
                listing={listing}
                isLazy={index > 3}
                onClick={() => openSelectedUrl(listing.id)}
                onMouseOver={() => {
                  updateFirstLoad()
                  updateSelectedLocation(listing.id)
                }}
                onMouseOut={() => updateSelectedLocation(-1)}
              />
            </Element>
          </span>
        )
      })}
      {showRecentsPanel ? null : <PaginationComponent />}
    </div>
  )
}

export default ListingsView
