import { configureStore } from '@reduxjs/toolkit'

// Import Reducers here
import detailsReducer from './details/slice'
import searchReducer from './search/slice'
import userReducer from './user/slice'

// todo remove triangle from state

const store = configureStore({
  reducer: {
    details: detailsReducer,
    search: searchReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['search/updateTriangle'],
        ignoredPaths: ['search.triangle'],
      },
      immutableCheck: { ignore: ['search.triangle'] },
    }),
    devTools: true,  // Enable Redux DevTools for testing purposes
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
